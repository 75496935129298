import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
  getDownloadURL
} from "firebase/storage";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { db } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import AlbumHeader from "./AlbumHeader";
import AlbumCover from "./AlbumCover";
import TrackList from "./TrackList";
import ConfirmDialog from '../../shared/ConfirmDialog';
import AlertDialog from '../../shared/AlertDialog';
import AlbumSettings from './AlbumSettings';
import useAudioPlayer from '../../shared/useAudioPlayer';

const reactRootDataset = document.getElementById("react-root").dataset;

const Album = () => {
  const maxRecordingTime = 2700; // 45 minutes

  // State variables
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tracks, setTracks] = useState([]);
  const { user } = useAuth();
  const albumId = reactRootDataset.albumId;
  const [userId, setUserId] = useState("");
  const [privacyLevel, setPrivacyLevel] = useState(null);
  const [sharedWith, setSharedWith] = useState([]);
  const navigate = useNavigate();
  const [editingTrackId, setEditingTrackId] = useState(null);
  const [editingTrackTitle, setEditingTrackTitle] = useState("");
  const [newTrackTitle, setNewTrackTitle] = useState("");
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [invitedTo, setInvitedTo] = useState([]);
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);

  const {
    isPlaying,
    playingTrackId,
    playingSegmentIndex,
    selectedTrackId,
    currentTime,
    duration,
    handlePause,
    handlePlayPause,
    handleSeek,
    handleDeleteSegment,
    startRecording,
    stopRecording,
    isRecording,
    recordingTrackId,
    remainingTime,
    browserNotSupported,
    alertDialogOpen,
    dialogTitle,
    dialogContent,
    setAlertDialogOpen,
    setDialogTitle,
    setDialogContent,
  } = useAudioPlayer(albumId, userId, user, sharedWith, setTracks);

  // Fetch album data
  useEffect(() => {
    const fetchAlbum = async () => {
      console.log("Fetching album with ID:", albumId);
      setLoading(true);
      setError(null);

      if (!albumId) {
        console.error("No albumId provided");
        setError("No album ID provided");
        setLoading(false);
        return;
      }

      try {
        const albumDoc = doc(db, "albums", albumId);
        console.log("Fetching album document...");
        const albumSnapshot = await getDoc(albumDoc);

        if (albumSnapshot.exists()) {
          console.log("Album document found");
          const albumData = albumSnapshot.data();
          console.log("Album data:", albumData);

          setTitle(albumData.title || "");
          setSubTitle(albumData.subtitle || "");
          setImageUrl(albumData.imageUrl || "");

          const tracksWithIds = (albumData.tracks || []).map(
            (track, index) => ({
              ...track,
              id: track.id || `track-${index}`,
            })
          );
          setTracks(tracksWithIds);

          if (albumData.userId) {
            console.log("Fetching creator info for userId:", albumData.userId);
            const creatorDoc = await getDoc(doc(db, "users", albumData.userId));
            if (creatorDoc.exists()) {
              const { firstName, lastName } = creatorDoc.data();
              setCreatorName(`${firstName} ${lastName}`);
              setSubscriptionLevel(creatorDoc.data().subscriptionLevel || 0);
            } else {
              console.log("Creator document not found");
            }
          }
          setUserId(albumData.userId || "");

          // Set privacy level and sharedWith
          setPrivacyLevel(albumData.privacyLevel);
          setSharedWith(albumData.sharedWith || []);
          setInvitedTo(albumData.invitedTo || []);

          // Check privacy settings
          if (user) {
            if (albumData.privacyLevel === 0 && albumData.userId !== user.uid) {
              navigate("/login");
              return;
            }
            if (
              albumData.privacyLevel === 1 &&
              albumData.userId !== user.uid &&
              !albumData.sharedWith.includes(user.uid)
            ) {
              navigate("/login");
              return;
            }
          } else {
            return;
          }
        } else {
          console.log("Album document not found");
          setError("Album not found");
        }
      } catch (error) {
        navigate(`/login?redirect_to=/album/${albumId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbum();

    // Real-time listener
    if (albumId) {
      const albumRef = doc(db, "albums", albumId);
      const unsubscribe = onSnapshot(albumRef, (doc) => {
        if (doc.exists()) {
          const albumData = doc.data();
          setTitle(albumData.title || "");
          setSubTitle(albumData.subtitle || "");
          setImageUrl(albumData.imageUrl || "");

          const tracksWithIds = (albumData.tracks || []).map(
            (track, index) => ({
              ...track,
              id: track.id || `track-${index}`,
            })
          );
          setTracks(tracksWithIds);

          setPrivacyLevel(albumData.privacyLevel);
          setSharedWith(albumData.sharedWith || []);
          setInvitedTo(albumData.invitedTo || []);
        }
      });

      // Clean up listener
      return () => unsubscribe();
    }
  }, [albumId, user, navigate]);

  const handleImageClick = (fileInputRef) => {
    if (user && (user.uid === userId || sharedWith.includes(user.uid))) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const storage = getStorage();
      const imageRef = ref(storage, `album_photos/${albumId}/cover.jpg`);
      await uploadBytes(imageRef, file);
      const newImageUrl = await getDownloadURL(imageRef);

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, { imageUrl: newImageUrl });

      setImageUrl(newImageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to upload image. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handleEditTrack = (trackId, currentTitle) => {
    setEditingTrackId(trackId);
    setEditingTrackTitle(currentTitle);
  };

  const handleSaveTrackTitle = async () => {
    if (!editingTrackId) return;

    try {
      const updatedTracks = tracks.map((track) =>
        track.id === editingTrackId
          ? { ...track, title: editingTrackTitle }
          : track
      );

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        tracks: updatedTracks,
        updatedAt: new Date()
      });

      setTracks(updatedTracks);
      setEditingTrackId(null);
    } catch (error) {
      console.error("Error updating track title:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to update track title. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handleCancelEdit = () => {
    setEditingTrackId(null);
    setEditingTrackTitle("");
  };

  const handleDeleteTrack = useCallback(
    async (trackId) => {
      if (!user || (user.uid !== userId && !sharedWith.includes(user.uid))) {
        setDialogTitle('Permission Denied');
        setDialogContent("You don't have permission to delete tracks from this album.");
        setAlertDialogOpen(true);
        return;
      }

      setDialogTitle('Confirm Delete');
      setDialogContent('Are you sure you want to delete this track? It will remove all of the recordings associated with it. This action cannot be undone.');
      setDialogAction(() => async () => {
        try {
          const trackIndex = tracks.findIndex((track) => track.id === trackId);
          if (trackIndex === -1) throw new Error("Track not found");

          const track = tracks[trackIndex];
          const updatedTracks = tracks.filter((t) => t.id !== trackId);

          const storage = getStorage();
          if (track.audioSegments) {
            for (const segment of track.audioSegments) {
              const m4aPath = `audio_segments/${userId}/${albumId}/segment-${segment.id}.m4a`;

              try {
                await deleteObject(ref(storage, m4aPath));
              } catch (error) {
                console.log("M4A file not found or already deleted");
              }
            }
          }

          const albumRef = doc(db, "albums", albumId);
          await updateDoc(albumRef, {
            tracks: updatedTracks,
            updatedAt: new Date()
          });

          setTracks(updatedTracks);
          console.log("Track deleted successfully");
        } catch (error) {
          console.error("Error deleting track:", error);
          setDialogTitle('Error');
          setDialogContent('Failed to delete track. Please try again.');
          setAlertDialogOpen(true);
        }
      });
      setConfirmDialogOpen(true);
    },
    [tracks, user, userId, sharedWith, albumId]
  );

  const handleAddTrack = async () => {
    if (!newTrackTitle.trim()) {
      setDialogTitle('Error');
      setDialogContent('Please enter a track title.');
      setAlertDialogOpen(true);
      return;
    }

    try {
      const newTrack = {
        id: `track-${Date.now()}`,
        title: newTrackTitle.trim(),
        audioSegments: [],
        updatedAt: new Date(),
      };

      const updatedTracks = [...tracks, newTrack];

      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        tracks: updatedTracks,
        updatedAt: new Date()
      });

      setTracks(updatedTracks);
      setNewTrackTitle("");
      setIsAddingTrack(false);
    } catch (error) {
      console.error("Error adding new track:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to add new track. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handlePrivacyChange = async (newLevel) => {
    try {
      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        privacyLevel: newLevel,
        updatedAt: new Date()
      });
      setPrivacyLevel(newLevel);
    } catch (error) {
      console.error("Error updating privacy level:", error);
      setDialogTitle('Error');
      setDialogContent('Failed to update privacy level. Please try again.');
      setAlertDialogOpen(true);
    }
  };

  const handleStopRecording = async () => {
    await stopRecording();
  };

  console.log("Creator Name:", creatorName);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Helmet>
        <title>{title} - Golden Record</title>
        <meta
          name="apple-itunes-app"
          content={`app-id=6501951254, app-argument=https://app.goldenrecord.app/album/${albumId}`}
        />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={subtitle} />
        <meta
          property="og:url"
          content={`https://app.goldenrecord.app/album/${albumId}`}
        />
        <meta name="apple-itunes-app" content="app-id=6501951254" />
      </Helmet>
      {browserNotSupported && (
        <AlertDialog
          open={alertDialogOpen}
          title="Browser Not Supported"
          content="Your browser does not support audio recording. Please use a modern browser like Chrome, Firefox, or Edge to record audio."
          onClose={() => setAlertDialogOpen(false)}
          okText="OK"
          okButtonProps={{ color: 'primary' }}
        />
      )}
      <AlbumHeader
        albumId={albumId}
        title={title}
        subtitle={subtitle}
        creatorName={creatorName}
        userId={userId}
        currentUserId={user ? user.uid : null}
      />
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={12} md={4}>
          <AlbumCover
            imageUrl={imageUrl}
            handleImageClick={handleImageClick}
            handleImageUpload={handleImageUpload}
            user={user}
            userId={userId}
            sharedWith={sharedWith}
          />
          <AlbumSettings
            privacyLevel={privacyLevel}
            onPrivacyChange={handlePrivacyChange}
            user={user}
            userId={userId}
            albumId={albumId}
            title={title}
            initialSharedWith={sharedWith}
            imageUrl={imageUrl}
            creatorName={creatorName}
            invitedTo={invitedTo}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <TrackList
            tracks={tracks}
            isPlaying={isPlaying}
            playingTrackId={playingTrackId}
            handlePlayPause={handlePlayPause}
            currentTime={currentTime}
            duration={duration}
            handleSeek={handleSeek}
            user={user}
            userId={userId}
            subscriptionLevel={subscriptionLevel}
            sharedWith={sharedWith}
            isRecording={isRecording}
            recordingTrackId={recordingTrackId}
            selectedTrackId={selectedTrackId}
            stopRecording={handleStopRecording}
            startRecording={startRecording}
            setTracks={setTracks}
            editingTrackId={editingTrackId}
            editingTrackTitle={editingTrackTitle}
            handleEditTrack={handleEditTrack}
            handleSaveTrackTitle={handleSaveTrackTitle}
            handleCancelEdit={handleCancelEdit}
            setEditingTrackTitle={setEditingTrackTitle}
            handleDeleteTrack={handleDeleteTrack}
            isAddingTrack={isAddingTrack}
            setIsAddingTrack={setIsAddingTrack}
            newTrackTitle={newTrackTitle}
            setNewTrackTitle={setNewTrackTitle}
            handleAddTrack={handleAddTrack}
            albumId={albumId}
            showTrackControls={true}
            remainingTime={remainingTime}
            playingSegmentIndex={playingSegmentIndex}
            handleDeleteSegment={handleDeleteSegment}
            handlePause={handlePause}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          if (dialogAction) {
            dialogAction();
          }
          setConfirmDialogOpen(false);
        }}
        confirmText="Confirm"
        cancelText="Cancel"
        confirmButtonProps={{ color: 'error' }}
        cancelButtonProps={{ color: 'primary' }}
      />
      <AlertDialog
        open={alertDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setAlertDialogOpen(false)}
        okText="OK"
        okButtonProps={{ color: 'primary' }}
      />
    </>
  );
};

export default Album;
